import { CSSProperties } from 'react';

import { WalletStyles } from '../pages/wallet/assets/walletStyles';
import { Role } from './acl';
import * as geo from './geo';
import * as twilio from './twilio';
export const Industries = {
	"cannabis": "Cannabis",
	"brewery": "Alcohol",
	"coffee": "Coffee",
	"nightlife": "Nightlife",
	"political": "Political",
	"entertainment": "Entertainment",
	"other": "Other",
} as const;
export type Industry = keyof typeof Industries;

export interface Profile {
	email?: string;
	cc?: string[];
	phone?: string;
	avatar?: string;
	name: string;
	website?: string;
	password?: string;
	isSub?: boolean;
	subType?: string;
	memberType?: string;
	industry: Industry;
	subUserTypes?: { [key: string]: string }
	timezone: string;
	notes?: string;
	subUsers?: string[];
};

export interface AllowedIPs {
	budtender?: string[];
	global?: string[];
}

export interface GoogleProfile {
	uuid: string;
	email: string;
	domain?: string;
	name?: string;
	verified?: boolean;
}

export interface MicrosoftProfile {
	uuid: string;
	email: string;
	name: string;
}


export interface RebirthStatus {
	uid: string
	status: 'success' | 'error' | 'default' | 'processing' | 'warning'
	domain: boolean
	lastRebirth: number
	rebirthType: string
}

export interface AccountCancellation {
	uid: string
	requester: string
	confirmer: string
	reason: string
	requested: number
	confirmed: number
}

export interface Login {
	id: string; // account uid
	email: string;
	password?: string;
	tokenKey?: string;
	subUser?: boolean;
	lastLogin?: number;
	sso?: 'google' | 'microsoft';
	globalID?: string;
	google?: GoogleProfile;
	microsoft?: MicrosoftProfile;
	role: Role;
	phone?: string;
	name?: string;
	timezone?: string;
	updated?: number;
	disabled: boolean;
}

export interface TokenSSO {
	provider: 'google' | 'microsoft';
	value: string;
}

export interface Onboarding {
	firstStore: number;
	finished: number;
	finishedSignup: number;
	steps?: { [key: string]: boolean }
	connections?: { [key: string]: string }
	lastStep?: string
};

export interface Address {
	zip?: string;
	city?: string;
	state?: string;
	country?: string;
};

export interface MicrosoftConfig {
	enabled: boolean;
	domain: string;
}

export interface GoogleConfig {
	enabled: boolean;
	domain: string;
}

export interface ImpliedConsent {
	enabled?: boolean;
	start?: number;
}

export interface User {
	id?: string;
	nickname?: string;
	agencyID?: string;
	type?: string;
	isIO?: boolean;
	created?: number;
	lastUpdated?: number;
	profile?: Profile;
	active?: boolean;
	freqCap?: boolean
	probation?: boolean;
	invoiceFreeze?: boolean;
	archived?: boolean;
	wipedDomain?: boolean;
	hasDynamicContent?: boolean;
	dispenseOrgID?: string;
	manualNums?: boolean;
	hasDynamicAbandonCart?: boolean;
	hasDynamicContentViaAPI?: boolean;
	// chargebee?: Customer;
	adomain?: string;
	rdomains?: string[];
	hidePointsInWallet?: boolean;
	pointsBlacklist?: string;
	apiKey?: string;
	sendgridKey?: string;
	currency?: string;
	shortyPrefix?: string
	verifiedOptin?: boolean;
	disableShorty?: boolean;
	infobipCmpID?: string;
	autoResponder?: { [key: string]: string };
	macroFallback?: { [key: string]: string };
	optInAttempts?: number;
	address?: Address;
	approvedTelnyxCampaign?: string;
	// importers
	// exporters
	// extensions
	cndResp?: { [key: string]: string };
	maxMTU?: number;
	spendByDay?: Record<string, number>;
	top20PercentileSpend?: number;
	optinMessage?: string;
	postOptinMessage?: string;
	msgContainer?: string;
	walletLoginMessage?: string;
	replySTOP?: string;
	nameMacroFallback?: string;
	mmsLogo?: boolean;
	nameDOBMerge?: boolean;
	postSaleMessage?: string;
	loyaltyCutoff?: number;
	lastInvoiceTime?: number;

	fees?: Fees;
	feesV2?: FeesV2;
	pendingFeesV2?: FeesV2
	pendingPlanRequested?: number    // Time the pending plan was requested
	pendingMessaging?: string
	msgingPlanRequested?: number    // Time the messaging plan was requested

	// invoicesList?: billing.InvoiceResponseData;
	// billing?: billing.CustomerData;
	subType: 'freemium' | 'brand' | 'retail' | 'vertical' | 'sandbox'
	signupNum?: twilio.IncomingPhoneNumber;
	telnyxSignup?: string;
	stores?: Store[];
	metadata?: {
		hasNativeApp?: boolean
		hasEcommerce?: boolean
	}
	onboarding?: Onboarding;
	lastImpor?: number;
	lastCapture?: number;
	lastBQ?: number;
	lastAccess?: number;
	utmTags?: boolean;
	allowAds?: boolean;
	allowCollectibleCodes?: boolean;
	disableWalledGarden?: boolean;
	disableAnonLoyalty?: boolean;
	optOutBounces?: boolean;
	hasCustomApp?: boolean;
	disableBlockAdjustments?: boolean;
	enableRelativeExpirationEarn?: boolean;
	requirePhoneCaptcha?: boolean;
	message2FA?: boolean;
	messageDOB?: boolean;
	masterVerification: boolean;
	masterVerificationPin: any;
	pos?: string;
	referredBy?: string;
	salesRep?: string;
	lastAudienceRun?: number;
	microsoft?: MicrosoftConfig;
	google?: GoogleConfig;
	brandOptOuts?: string[];
	impliedConsent?: ImpliedConsent;

	reportStorage?: { [k: string]: string }
	reportStoragePersona?: { [k: string]: string }
	reportStorageDebug?: { [k: string]: string }

	newWebWallet?: boolean // ! To use legacy or new wallet, should be true by default for new accounts

	disableImplicitEmail?: boolean
	showPerStoreBilling?: boolean


	excludedFromCmpApprovalNotifs: string[]





	billing?: any                        //billing *billing.CustomerData
	chargebee?: any                        //chargebee *customer.Customer
	brandAliases?: BrandTargets               //brandTargets otk.Set
	btByRegion?: any                        //btByRegion map[string]otk.Set
	thirdPartyStores?: any                        //thirdPartyStores map[string][]int
	invoiceCSV?: any                        //invoiceCSV map[string]int64
	pr?: any                        //pr *Priority
	optinImage?: string                        //optinImage string
	tcmpID?: any                        //tcmpID string
	exporters?: any                        //exporters integrations.Exporters
	extensions?: any                        //extensions integrations.Extensions
	importers?: any                        //importers integrations.Importers
	invoicesList?: any                        //invoicesList []*billing.InvoiceResponseData
	totalRevenue?: number                        //totalRevenue float64
	lastBrandTargetUpdate?: number                        //lastBrandTargetUpdate int64
	lastRebirth?: number                        //lastRebirth int64
	frsExport?: number                        //frsExport int64
	lastProbation?: number                        //lastProbation int64
	lastSpotlight?: number                        //lastSpotlight int64
	refreshTS?: number                        //refreshTS int64
	refreshDomain?: boolean                        //refreshDomain bool
	refreshPhones?: boolean                        //refreshPhones bool
	allowBanned?: boolean                        //allowBanned bool
	blockDataSharing?: boolean                        //blockDataSharing bool
	brandStoresForMarketAnalytics?: boolean                        //brandStoresForMarketAnalytics bool

	nextRefreshFree?: boolean                        //nextRefreshFree int64

	sandboxTerms?: SandboxTerms

}

export type SandboxTerms = {
	timestamp?: number
	acceptedBy?: string
}

export type BrandTargets = { [key: string]: { [k: string]: string[] } }

export interface StoreReviewTemplate {
	google?: string;
	facebook?: string;
	facebookPageID?: string;
	leafly?: string;
	weedmaps?: string;
	instagram?: string;
	twitter?: string;
	yelp?: string;
	appleStore?: string;
	googlePlay?: string;
};

export interface PublicStore {
	addr?: geo.MailingAddress;
	state: string;
	name: string;
	nickname: string;
	phone: string;
	isBrandStore?: boolean;
	onlineShopURL: string;
	customURL: string;
	customURLAndroid: string;
	embeddedURL: string;
	embeddedMedURL: string;
	disclaimer?: string;
	city: string;
	country: string;
	zipCode: string;
	id: number;
}

export interface Store {
	addr?: geo.MailingAddress;
	signupNumber?: twilio.IncomingPhoneNumber;
	sourceMapping?: { [key: string]: string[] };
	tollFree?: twilio.IncomingPhoneNumber;
	reviewLinks?: StoreReviewTemplate;
	notes?: string;
	avatar?: string;
	userID?: string;
	nickname?: string;
	domain?: string;
	onlineShopURL?: string;
	customURL?: string;
	customURLAndroid?: string;
	embeddedURL?: string;
	embeddedMedURL?: string;
	phone?: string;
	venueID?: string;
	globalID?: string;
	name?: string;
	telnyxSignup?: string;
	email?: string;
	disclaimer?: string;
	scraperMapping?: string;
	rating?: number;
	ranking?: number;
	id?: number;
	created_at?: number;
	signupViaPhone?: boolean;
	optInQueue?: boolean;
	llOptInQueue?: boolean;
	alleavesOptInQueue?: boolean;
	meadowOptInQueue?: boolean;
	archived?: boolean;
	isBrandStore?: boolean;
	hiddenFromForms?: boolean;
	dynamicContentCustomURL?: boolean;

	crewCount?: number;
	generalManager?: string;
};

export interface StoreMappings {
	[source: string]: string[]
};

export interface TelenexPhones {
	ID: string
	mid: string
	numbers: TelenxPhone[]
	removed: TelenxPhone[]
	created: number
	updated: number
}
export interface TelenxPhone {
	id: string
	phone_number: string
	removeTS: TelenxPhone[]
}

export interface AppSettings extends AppSettingsContent, WalletStyles {
	name?: string
	allowChangeStore?: boolean
	android?: {
		desc?: string,
		icon?: string,
		name?: string
		svcAcc?: string
		previewImages?: string[],
	},
	apple?: {
		desc?: string
		icon?: string,
		keywords?: string
		name?: string
		previewImages?: string[],
		subtitle?: string
		appid?: string
		teamid?: string
	},
	storeStyles?: {
		[storeID: string | number]: AppSettingsContent
	}
	verbiage?: {
		[key: string]: string
	}
	showPromptHomeScreen?: boolean
	prizeWheel?: PrizeWheelSettings
}

export interface AppSettingsContent {
	brand?: {
		bgImage?: string[],
		cover?: string
		iconColor?: string
		loading?: string,
		logo?: string
		splash?: string
	},
	hideCoverAvatarLogo?: boolean
	hideBrowser?: boolean
	msgBG?: string
	borderColor?: string
	button?: CSSProperties
	buttonDanger?: CSSProperties
	buttonDangerText?: CSSProperties
	buttonGhost?: CSSProperties
	buttonText?: CSSProperties
	caption?: CSSProperties
	collapse?: CSSProperties
	consentText?: string
	container?: CSSProperties
	customURL?: string
	messageColor?: string
	customURLAndroid?: string
	// dangerButton?: CSSProperties
	error?: CSSProperties
	eulaURL?: string
	fromEmail?: string
	h2?: CSSProperties
	icon?: CSSProperties
	input?: CSSProperties
	link?: CSSProperties
	navBackgroundColor?: string,
	privacyURL?: string
	progress?: any
	referBox?: CSSProperties
	referLink?: CSSProperties
	sectionTitle?: CSSProperties
	shopURL?: string
	switch?: CSSProperties
	termsURL?: string,
	theme?: string
	titleColor?: string
	hideAddress?: boolean,
	hideProfileAddress?: boolean,
	hideDOB?: boolean,
	enableScanToPay?: boolean,
	showGeneralInfo?: boolean,
	hideOrderStore?: boolean
	hideOrderPrice?: boolean
	hideCall?: boolean,
	hideCallStore?: true,
	hideDirections?: boolean,
	hideIcons?: boolean
	hideDiscountOpen?: boolean,
	hideEmails?: boolean,
	hideStores?: boolean,
	hideAllDiscounts?: boolean,
	hideFavStoreAddr?: true,
	hideMessages?: boolean,
	showDealsTab?: boolean,
	hideOldMSGs?: string,
	hidePoints?: boolean,
	hideRAF?: boolean,
	hideShop?: boolean,
	hideSocial?: boolean,
	hideDefFAQ?: boolean,
	redeemButtonText?: string,
	redeemInStoreText?: string,
	redeemTitle?: string,
	containerInternal?: CSSProperties,
	faqs?: {
		body?: string,
		title?: string
	}[],
	mainBGColor?: string
	ghostButton?: CSSProperties,
	raf?: CSSProperties,
	referbox?: CSSProperties,
	requiredSignupFields?: string[],
	section?: CSSProperties,
	signupAfterLogin?: boolean,
	topBar?: CSSProperties
	buttonGhostText?: CSSProperties,
	inputV2?: CSSProperties
	row?: CSSProperties
	col?: CSSProperties
	item?: CSSProperties,
	itemLeft?: CSSProperties,
	itemRight?: CSSProperties,
	collapseBody?: CSSProperties,
	centeredView?: CSSProperties
	modalView?: CSSProperties
	body?: CSSProperties
	round?: CSSProperties
	fullWidth?: CSSProperties
	col60?: CSSProperties
	col50?: CSSProperties
	col20?: CSSProperties
	p?: CSSProperties
	bold?: CSSProperties
	discTitle?: CSSProperties
	ts?: CSSProperties
	buttonTextSm?: CSSProperties
	iconButtonText?: CSSProperties
	buttonDeleteText?: CSSProperties
	buttonCancelText?: CSSProperties
	modalTitle?: CSSProperties
	modalText?: CSSProperties
	xxsIcon?: CSSProperties
	xxsIconClr?: CSSProperties
	iconButton?: CSSProperties
	caret?: CSSProperties
	buttonIcon?: CSSProperties
	buttonSignout?: CSSProperties
	buttonConfirmGreen?: CSSProperties
	buttonDelete?: CSSProperties
	buttonCancel?: CSSProperties
	backgroundImage?: CSSProperties
	splash?: CSSProperties
	borderBottom?: CSSProperties
	border?: string

	homeTab?: string
	dealsTab?: string
	messageTab?: string
	shopTab?: string
	discoverTab?: string
	socialTab?: string
	profileTab?: string
	helpTab?: string

	hideConnectTab?: boolean
	hideCustom?: boolean
	showPointsPill?: boolean

	navIcon?: string
	tabTitle?: string
	boldFont?: string
	navBG?: string

	hideOrders?: boolean
	showTopProducts?: boolean
	showOrderHistoryFeed?: boolean
	hideReorderButtons?: boolean

	// ! Settings for the new skin
	headerIconColor?: string
	messageTabTitle?: string
	rewardTabTitle?: string
	profileTabTitle?: string
	ordersTab?: string
	ordersTabTitle?: string
	faqsTabTitle?: string

	popularTabTitle?: string
	allProductsTabTitle?: string

	messageTabTitleReverse?: boolean
	allProductsTabTitleReverse?: boolean

	dontRedirectWebWalletShop?: boolean
	dontRedirectWebWalletCustom?: boolean
	forceCustomTabRedirect?: boolean
	forceShopTabRedirect?: boolean

	hidePopularProductsSlider?: boolean
	hidePopularProductsTab?: boolean
	hideReviewStars?: boolean
}


export interface PrizeWheelSettings {
	enabled?: boolean
	primary?: string
	secondary?: string
	notchColor?: string
	wheelFrequency?: WheelFrequency
}

export interface WheelFrequency {
	freq?: number
	freqUnit?: string
	start?: any
}



export interface UserSettings {
	userName?: string

	app?: AppSettings
	hasNewSkin?: boolean // ! Has this client switched over to using the new app skins
	disableShopLock?: boolean
	forceShop?: boolean
	hideLegacyWallet?: boolean //! has this client switched over to the new wallet designs
	css?: {
		button: string,
		disabledButton: string,
		h1: string,
		override: string,
		progressColor: string,
		progressSuccessColor: string
	}
	data?: UserSettingsData
	fonts?: string[]
	icons?: { [icon: string]: string }
	toggles?: UserSettingsToggles

	byStore?: { [storeID: number | string]: UserSettings }

}

export interface UserSettingsToggles {
	allowCustomStore?: boolean,
	disableRedeemInStore?: boolean,
	hideRedeemInStore?: boolean,
	hideBar?: boolean,
	hideLogo?: boolean,
	hideName?: boolean,
	hidePoints?: boolean,
	squareLogo?: boolean
	hideBrowser?: boolean
	enablePin?: boolean
}

export interface UserSettingsData {
	name?: string
	notice?: string
	addrLabel?: string,
	captchaSubmit?: string,
	captchaText?: string,
	cover?: string,
	logo?: string,
	mmsLogo?: string,
	pointsAlias?: string,
	storeShopURL?: string
	redeemInStoreText?: string
	redeemTitle?: string
}

export interface Fees {
	'0to10k'?: number
	'10to20k'?: number
	'20to40k'?: number
	'40to100k'?: number
	'100to200k'?: number
	'200k'?: number
	'400k'?: number
	address?: geo.MailingAddress
	chargePerCPMAds?: number
	chargePerSentMMS?: number
	chargePerSentEmail?: number
	chargePerSentBrowser?: number
	chargePerSentPush?: number
	chargePerSentSnailMail?: number
	chargePerReceivedMMS?: number
	chargePerSentSMS?: number
	chargePerReceivedSMS?: number
	chargePerStoreLicense?: number
	chargePerStoreManagedService?: number
	premiumSupportCharge?: number
	premiumSupportPlusCharge?: number
	brandLicenseCharge?: number
	nativeAppCharge?: number
	chargePerNumber?: number
	chargePerDomain?: number
	chargePerCallMinute?: number;
	nativeAppStartDate?: number
	flatFee?: number
	isFranchise?: boolean
	transactionPricing?: boolean
	firstMonthTransactions?: number
	firstMonthStores?: number
	billingEmail?: string
	coupon?: string
	name?: string
	currency?: string
	ccEmails?: string[]
	planType?: number
	billingCreation?: number
	ecommStores?: number
	ecommLicenseCharge?: number
}

export interface FeesV2 {
	address?: geo.MailingAddress
	packages?: Packages
	billingEmail?: string
	name?: string
	ccEmails?: string[]
	bundle?: BundleTypes
	messaging?: MessagingCreditPlans            // Messaging credit plan
	messagingDefaults?: MessagingDefaults       // Number of credits for each messaging type

	billingCreation?: number
	contractStart?: number
	termLength?: number                         // Days

	nativeAppStartDate?: number
	nativeAppTermLength?: number

	coupon?: string

	ecommStores?: number // number of ecomm stores
	ecommLicenseCharge?: number
	customDomainCount?: number
	marketSpotlightStateCount?: number
	marketSpotlightCountryCount?: number
	marketSpotlightAliasCount?: number
	contactCount?: number
	customOverage?: number
}

export interface MessagingDefaults {
	mms: number        // How many credits is each MMS worth?
	sms: number        // How many credits is each SMS worth?
	email: number      // How many credits is each Email worth?
	push: number       // How many credits is each Push worth?
	directMail: number // How many credits is each direct mail worth?
	call: number 	   // How many credits is each call minute worth?

}

export type PackageType = BasicPackage | AnalyticsPackage
export interface Package { priceOverride?: number }
export interface BasicPackage extends Package { type?: BasicTiers; }
export interface AnalyticsPackage extends Package { type?: AnalyticsTiers; }

export interface Packages {
	marketing?: BasicPackage
	loyalty?: BasicPackage
	analytics?: AnalyticsPackage
	brandAnalytics?: AnalyticsPackage
	dataops?: BasicPackage
	support?: BasicPackage
	nativeApps?: BasicPackage
	crew?: BasicPackage
}


// export type PackageTypes = 'marketing' | 'loyalty' | 'analytics' | 'dataops' | 'support' | 'nativeApps'
export type PackageTypes = keyof Packages
export type BasicTiers = 'starter' | 'pro' | 'enterprise'
export type AnalyticsTiers = 'starter' | 'pro' | 'brand-starter' | 'brand-pro'
export type AllTiers = BasicTiers | AnalyticsTiers

export type BundleTypes = "starter-bundle" | "starter-brand-bundle" | "pro-bundle" | "pro-brand-bundle" | "enterprise-bundle" | "enterprise-brand-bundle"

export type Addons = 'custom-domain' | 'market-spotlight-state' | 'market-spotlight-country' | 'market-spotlight-alias' | 'contact'

export type MessagingCreditPlans =
	'messaging-0k' |
	'messaging-8k' |
	'messaging-20k' |
	'messaging-60k' |
	'messaging-125k' |
	'messaging-300k' |
	'messaging-525k' |
	'messaging-825k' |
	'messaging-1175k' |
	'messaging-1500k' |
	'messaging-2000k' |
	'messaging-2500k' |
	'messaging-3000k' |
	'messaging-3500k' |
	'messaging-6000k' |
	'messaging-10000k' |
	'messaging-15000k' |
	'messaging-20000k' |
	'messaging-30000k' |
	'messaging-50000k';

export interface TransactionalStats {
	sent: number   // Number of messages sent
	success: number   // Number of messages sent successfully
	errors: number   // Number of messages errored
	messages: TrimmedMessage[]   // Last 50 messages sent given the constraints
}

export interface TrimmedMessage {
	to: string
	body: string
	deliveryStatus: string
	ts: number
}


export type UserPagination = {
	totalItems: number
	totalResults: number
	totalResultsPages: number
	page: number
	size: number
}


export interface FullUserQuery {
	trimResponse?: boolean
	query: UserQuery
	pagination: UserPagination
	sort: SortFields
}


export interface UserQueryResponse {
	users: User[]
	pagination: UserPagination
}

export interface UserQuery {
	search?: string
	agencyID?: string
	uid?: string
	name?: string
	email?: string
	phone?: string
	type?: string
	subType?: string
	website?: string
	address?: Address
	active?: number;
	archived?: number;
	hasNewBilling?: boolean
	andLogic?: boolean
}

export interface SortFields {
	id?: SortField
	name?: SortField
	lastCapture?: SortField
	lastAudienceRun?: SortField
	stores?: SortField
	created?: SortField
}

export interface SortField {
	direction: 'asc' | 'desc'
	priority?: number
}