import { z } from 'zod';
import { isValidTelnyxEntityType, isValidTelynxVerticalType, utils, validatePhoneNumber, validateURL } from '../helpers';
import { colors } from '../helpers';
import { TelynxIdentityStatusTypes, TelnyxVerticalTypes, TelynxEntityTypes, TelynxStockExchanges, TelynxUseCaseTypes, TelynxStatusTypes } from '../constants';

const filteredIndustries = ['alcohol', 'cannabis']
export const isRestricted = (industry?: string) => {
    if (!industry) industry = utils.user.profile?.industry ?? '';
    return filteredIndustries.includes(industry)
}

const EntityTypeKeys = Object.keys(TelynxEntityTypes) as [keyof typeof TelynxEntityTypes, ...Array<keyof typeof TelynxEntityTypes>];
const TelnyxEntityTypesSchema = z.enum(EntityTypeKeys)
const VerticalKeys = Object.keys(TelnyxVerticalTypes) as [keyof typeof TelnyxVerticalTypes, ...Array<keyof typeof TelnyxVerticalTypes>];
const TelnyxVerticalTypesSchema = z.enum(VerticalKeys);
const StatusKeys = Object.keys(TelynxStatusTypes) as [keyof typeof TelynxStatusTypes, ...Array<keyof typeof TelynxStatusTypes>];
const TelnyxStatusTypesSchema = z.enum(StatusKeys)
const IdentityStatusKeys = Object.keys(TelynxIdentityStatusTypes) as [keyof typeof TelynxIdentityStatusTypes, ...Array<keyof typeof TelynxIdentityStatusTypes>];
const TelnyxIdentityStatusTypesSchema = z.enum(IdentityStatusKeys)
const TelnyxCampaignSubmssionStatuses = ["CREATED", "FAILED", "PENDING"] as const;
const TelnyxCampaignSubmissionStatusSchema = z.enum(TelnyxCampaignSubmssionStatuses)

export const FailureCategorySchema = z.object({
    id: z.string(),
    displayName: z.string(),
    description: z.string(),
    fields: z.array(z.string())
})
export const FailureDataSchema = z.object({
    brandId: z.string().optional(),
    category: z.array(FailureCategorySchema).optional()
})

export const TelnyxBrandSchema = z.object({
    displayName: z.string(),
    ein: z.string(),
    street: z.string(),
    city: z.string(),
    state: z.string(),
    postalCode: z.string(),
    country: z.string(),
    phone: z.string().refine((phone) => {
        if (!phone) return true;
        return validatePhoneNumber(phone)
    }, {
        message: "Invalid phone number.",
    }),
    email: z.string().email("Invalid email address."),
    vertical: TelnyxVerticalTypesSchema.refine((value) => isValidTelynxVerticalType(value as TelnyxVerticalType), {
        message: 'Invalid vertical (sector) type',
    }),
    entityType: TelnyxEntityTypesSchema.refine((value) => isValidTelnyxEntityType(value as TelynxEntityType), {
        message: 'Invalid entity type',
    }),
    website: z.string().optional().refine((site) => {
        if (!site) return true; // If no site is provided, validation passes
        return validateURL(site)
    }, {
        message: "Invalid website URL. Please make sure to use http:// or https://",
    }),
    companyName: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    stockSymbol: z.string().optional(),
    stockExchange: z.string().optional(),
    ipAddress: z.string().optional(),
    isReseller: z.boolean().optional(),
    mock: z.boolean().optional(),
    mobilePhone: z.string().optional(),
});

export const TelnyxGetBrandSchema = TelnyxBrandSchema.extend({
    cspId: z.string().optional(),
    brandId: z.string().optional(),
    altBusinessID: z.string().optional(),
    universalEin: z.string().optional(),
    identityStatus: TelnyxIdentityStatusTypesSchema.optional(),
    referenceID: z.string().optional(),
    optionalAttributes: z.object({
        taxExemptStatus: z.string().optional(),
    }).optional(),
    status: TelnyxStatusTypesSchema.optional(),
    failureReasons: z.array(z.string()).optional(),
    failureData: FailureDataSchema.optional(),
});

export type TelnyxBrand = z.infer<typeof TelnyxBrandSchema>;
export type TelnyxGetBrand = z.infer<typeof TelnyxGetBrandSchema>;
export type TelnyxStatusType = keyof typeof TelynxIdentityStatusTypes;
export type TelnyxVerticalType = keyof typeof TelnyxVerticalTypes;
export type TelynxEntityType = keyof typeof TelynxEntityTypes;
export type TelynxUseCaseType = keyof typeof TelynxUseCaseTypes;
export type TelynxStockExchange = keyof typeof TelynxStockExchanges;
export type FailureCategory = z.infer<typeof FailureCategorySchema>;
export type FailureData = z.infer<typeof FailureDataSchema>;


export const TelnyxGetCampaignSchema = z.object({
    ageGated: z.boolean().optional(),
    autoRenewal: z.boolean().optional(),
    billedDate: z.string().optional(),
    brandId: z.string().optional(),
    campaignId: z.string().optional(),
    createDate: z.string().optional(),
    cspId: z.string().optional(),
    description: z.string().optional(),
    directLending: z.boolean().optional(),
    embeddedLink: z.boolean().optional(),
    embeddedPhone: z.boolean().optional(),
    helpKeywords: z.string().optional(),
    helpMessage: z.string().optional(),
    messageFlow: z.string().optional(),
    mock: z.boolean().optional(),
    nextRenewalOrExpirationDate: z.string().optional(),
    numberPool: z.boolean().optional(),
    optinKeywords: z.string().optional(),
    optinMessage: z.string().optional(),
    optoutKeywords: z.string().optional(),
    optoutMessage: z.string().optional(),
    referenceId: z.string().optional(),
    resellerId: z.string().optional(),
    sample1: z.string().optional(),
    sample2: z.string().optional(),
    sample3: z.string().optional(),
    sample4: z.string().optional(),
    sample5: z.string().optional(),
    status: z.string().optional(),
    subUsecases: z.array(z.string()).optional(),
    subscriberHelp: z.boolean().optional(),
    subscriberOptin: z.boolean().optional(),
    subscriberOptout: z.boolean().optional(),
    termsAndConditions: z.boolean().optional(),
    usecase: z.string().optional(),
    webhookURL: z.string().optional(),
    webhookFailoverURL: z.string().optional(),
    isTMobileRegistered: z.boolean().optional(),
    isTMobileSuspended: z.boolean().optional(),
    failureReasons: z.any().optional(),
    submissionStatus: TelnyxCampaignSubmissionStatusSchema.optional(),
});

export type TelnyxGetCampaign = z.infer<typeof TelnyxGetCampaignSchema>;

export interface GetTelnyxCampaignOptions {
    ageGated?: boolean
    autoRenewal?: boolean
    billedDate?: string
    brandId?: string
    campaignId?: string
    createDate?: string
    cspId?: string
    description?: string
    directLending?: boolean
    embeddedLink?: boolean
    embeddedPhone?: boolean
    helpKeywords?: string
    helpMessage?: string
    messageFlow?: string
    mock?: boolean
    nextRenewalOrExpirationDate?: string
    numberPool?: boolean
    optinKeywords?: string
    optinMessage?: string
    optoutKeywords?: string
    optoutMessage?: string
    referenceID?: string
    resellerId?: string
    sample1?: string
    sample2?: string
    sample3?: string
    sample4?: string
    sample5?: string
    status?: string
    subUsecases?: string[]
    subscriberHelp?: boolean
    subscriberOptin?: boolean
    subscriberOptout?: boolean
    termsAndConditions?: boolean
    usecase?: string
    webhookURL?: string
    webhookFailoverURL?: string
    isTMobileRegistered?: boolean
    isTMobileSuspended?: boolean
}

export const getStatusColor = (status: string): string => {
    switch (status) {
        case 'VERIFIED':
        case 'VETTED_VERIFIED':
            return colors.primary.primary;
        case 'SELF_DECLARED':
            return colors.primary.blue;
        case 'UNVERIFIED':
            return colors.primary.pinkRed;
        default:
            return '';
    }
};

export const getStatusText = (status: string): string => {
    switch (status) {
        case 'VERIFIED':
            return 'Verified';
        case 'VETTED_VERIFIED':
            return 'Vetted Verified';
        case 'SELF_DECLARED':
            return 'Self Declared';
        case 'UNVERIFIED':
            return 'Unverified';
        default:
            return '';
    }
};


